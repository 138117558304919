export function validarRemessa(obj) {
    let objetoEspelho = {
        nm_devedor: 'Nome do devedor',
        ds_endereco_devedor: 'endereco do devedor, 172',
        ds_bairro_devedor: 'bairro do devedor',
        cd_cep_devedor: '08747000',
        nm_cidade_devedor: 'Curitiba',
        cd_uf_devedor: 'SP',
        tp_identificacao_devedor: 2,
        nu_identificacao_devedor: '00224466884',
        nm_sacador: 'Nome Sacador',
        cnpj_sacador: '50804968000149',
        ds_endereco_sacador: 'Endereco do sacador, 115',
        nm_cidade_sacador: 'Cidade Sacador',
        cd_cep_sacador: '90240040',
        cd_uf_sacador: 'RS',
        nm_cedente_favorecido: 'Nome do Cedente',
        ds_numero_titulo: '123456',
        nu_parcela: '1',
        ds_nosso_numero: '',
        cd_especie_titulo: 'CBI',
        tp_aceite: '',
        tp_declaracao: 'C',
        tp_endosso: '',
        vl_titulo: '102.01',
        vl_saldo_protesto: '1002.82',
        dt_emissao: '2019-08-15',
        dt_vencimento: '2019-08-28',
        ds_email_devedor: 'emaildevedor@emaildevedor.com,',
        nu_telefone_devedor: '11999999999',
        ds_opcional_01: "{'CODIGO_INTERNO':'12345678'}",
        cd_carteira: 'Cobrança veicular',
        ds_agrupador: 'A01'
    };

    // Formatar o objeto
    for (let key in obj) {
        if (objetoEspelho.hasOwnProperty(key)) {
            if (typeof objetoEspelho[key] === 'number' && !isNaN(obj[key])) {
                obj[key] = Number(obj[key]);
            } else if (objetoEspelho[key] === null) {
                obj[key] = null;
            } else {
                obj[key] = String(obj[key]);
            }
        } else {
            delete obj[key];
        }
    }

    return obj;
}

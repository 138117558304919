<template>
    <div id="margin-dialog-body" style="width: 1200px">
        <v-card>
            <v-toolbar flat dark color="primary">
                <v-btn icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-col cols="12" sm="20" align="left">
                    <v-toolbar-title>Ofertas Título </v-toolbar-title>
                </v-col>
                <v-col cols="12" sm="9" align="right"> </v-col>
            </v-toolbar>
        </v-card>

        <v-card>
            <v-container>
                <v-card-title class="headline primary--text"
                    >Informações do Título:
                </v-card-title>

                <v-col sm="12" lg="12" class="mt-3">
                    <v-card class="grey lighten-4">
                        <v-row>
                            <v-col cols="1" sm="12" lg="2">
                                <v-sheet
                                    class="v-sheet--offset ml-3 text-center"
                                    color="primary"
                                    elevation="12"
                                    max-width="100%"
                                >
                                    <v-icon
                                        color="white"
                                        size="50"
                                        class="ma-1 pa-1"
                                    >
                                        format_list_bulleted
                                    </v-icon>
                                </v-sheet>
                            </v-col>
                            <v-col cols="1" sm="12" lg="2">
                                <v-card-text class="pt-0">
                                    <div class="font-weight-light grey--text">
                                        ID do título
                                    </div>
                                    <div
                                        class="
                                            primary--text
                                            font-weight-black
                                            title
                                        "
                                    >
                                        {{ detalhe_titulo.id_titulo }}
                                    </div>
                                </v-card-text>
                            </v-col>
                            <v-col cols="1" sm="12" lg="2">
                                <v-card-text class="pt-0">
                                    <div class="font-weight-light grey--text">
                                        Título
                                    </div>
                                    <div
                                        class="
                                            primary--text
                                            font-weight-black
                                            title
                                        "
                                    >
                                        {{ detalhe_titulo.ds_numero_titulo }}
                                    </div>
                                </v-card-text>
                            </v-col>
                            <v-col cols="1" sm="12" lg="2">
                                <v-card-text class="pt-0">
                                    <div class="font-weight-light grey--text">
                                        Parcela
                                    </div>
                                    <div
                                        class="
                                            primary--text
                                            font-weight-black
                                            title
                                        "
                                    >
                                        {{ detalhe_titulo.nu_parcela }}
                                    </div>
                                </v-card-text>
                            </v-col>
                            <v-col cols="1" sm="12" lg="2">
                                <v-card-text class="pt-0">
                                    <div class="font-weight-light grey--text">
                                        Saldo Protestado
                                    </div>
                                    <div
                                        class="
                                            primary--text
                                            font-weight-black
                                            title
                                        "
                                    >
                                        R$
                                        {{
                                            detalhe_titulo.vl_saldo_protesto_formatado
                                        }}
                                    </div>
                                </v-card-text>
                            </v-col>
                            <v-col cols="1" sm="12" lg="2">
                                <v-card-text class="pt-0">
                                    <div class="font-weight-light grey--text">
                                        Valor Título
                                    </div>
                                    <div
                                        class="
                                            primary--text
                                            font-weight-black
                                            title
                                        "
                                    >
                                        R$
                                        {{ detalhe_titulo.vl_titulo_formatado }}
                                    </div>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" class="ml-1">
                    <v-card-title class="headline primary--text">
                        Ofertas Globais:
                    </v-card-title>
                    <v-data-table
                        :headers="headersGlobal"
                        :items="ofertasGlobais"
                        item-key="id"
                        class="elevation-2"
                    >
                        <template v-slot:[`item.data_inicio`]="{ item }">
                            <span>
                                {{ formatDate(item.data_inicio) }}
                            </span>
                        </template>
                        <template v-slot:[`item.data_fim`]="{ item }">
                            <span>
                                {{ formatDate(item.data_fim) }}
                            </span>
                        </template>
                        <template
                            v-slot:[`item.desconto_porcentagem`]="{ item }"
                        >
                            <span>
                                {{
                                    formatPorcentagem(item.desconto_porcentagem)
                                }}
                            </span>
                        </template>
                        <template v-slot:[`item.juros_boleto`]="{ item }">
                            <span>
                                {{
                                    formatPorcentagemJurosBoleto(
                                        item.juros_boleto
                                    )
                                }}
                            </span>
                        </template>
                        <template v-slot:[`item.juros_cartao`]="{ item }">
                            <span>
                                {{
                                    formatPorcentagemJurosCartao(
                                        item.juros_cartao
                                    )
                                }}
                            </span>
                        </template>
                        <template v-slot:[`item.parcela_cartao`]="{ item }">
                            <span>
                                {{ formatParcelasCartao(item.parcela_cartao) }}
                            </span>
                        </template>
                        <template v-slot:[`item.parcela_boleto`]="{ item }">
                            <span>
                                {{ formatParcelasBoleto(item.parcela_boleto) }}
                            </span>
                        </template>
                        <template v-slot:[`item.desconto`]="{ item }">
                            <span>
                                {{ formatDinheiro(item.desconto) }}
                            </span>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-switch
                                :color="item.status == true ? 'green' : 'red'"
                                readonly
                                v-model="item.status"
                                inset
                                :style="{
                                    color: item.status == true ? 'green' : 'red'
                                }"
                            >
                            </v-switch>
                        </template>
                        <template v-slot:[`item.cartao`]="{ item }">
                            <v-switch
                                :color="
                                    item.cartao == true && item.status == true
                                        ? 'green'
                                        : 'grey'
                                "
                                readonly
                                v-model="item.cartao"
                                inset
                                :disabled="item.status"
                                :style="{
                                    color:
                                        item.cartao == true &&
                                        item.status == true
                                            ? 'green'
                                            : 'grey'
                                }"
                            >
                            </v-switch>
                        </template>
                        <template v-slot:[`item.boleto`]="{ item }">
                            <v-switch
                                :color="
                                    item.boleto == true && item.status == true
                                        ? 'green'
                                        : 'grey'
                                "
                                readonly
                                v-model="item.boleto"
                                inset
                                :disabled="item.status"
                                :style="{
                                    color:
                                        item.boleto == true &&
                                        item.status == true
                                            ? 'green'
                                            : 'grey'
                                }"
                            >
                            </v-switch>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" class="ml-1">
                    <v-card-title class="headline primary--text">
                        Ofertas Título:
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="ofertas"
                        item-key="ofertas"
                        class="elevation-2"
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn
                                v-if="item.status"
                                elevation="1"
                                width="100%"
                                @click="botaodesabilitarOferta(item)"
                                class="mr-2 grey white--text"
                            >
                                <v-icon
                                    medium
                                    class="mr-2"
                                    @click="botaodesabilitarOferta(item)"
                                    :style="{ color: 'white' }"
                                    >close
                                </v-icon>
                                Desabilitar
                            </v-btn>
                            <v-btn
                                v-if="!item.status"
                                elevation="1"
                                width="100%"
                                disabled
                                class="mr-2 grey white--text"
                            >
                                <v-icon
                                    medium
                                    class="mr-2"
                                    :style="{ color: 'white' }"
                                    >mdi-information
                                </v-icon>
                                Desabilitado
                            </v-btn>
                        </template>

                        <template v-slot:[`item.data_inicio`]="{ item }">
                            <span>
                                {{ formatDate(item.data_inicio) }}
                            </span>
                        </template>
                        <template v-slot:[`item.data_fim`]="{ item }">
                            <span>
                                {{ formatDate(item.data_fim) }}
                            </span>
                        </template>
                        <template
                            v-slot:[`item.desconto_porcentagem`]="{ item }"
                        >
                            <span>
                                {{
                                    formatPorcentagem(item.desconto_porcentagem)
                                }}
                            </span>
                        </template>
                        <template v-slot:[`item.juros_boleto`]="{ item }">
                            <span>
                                {{
                                    formatPorcentagemJurosBoleto(
                                        item.juros_boleto
                                    )
                                }}
                            </span>
                        </template>
                        <template v-slot:[`item.juros_cartao`]="{ item }">
                            <span>
                                {{
                                    formatPorcentagemJurosCartao(
                                        item.juros_cartao
                                    )
                                }}
                            </span>
                        </template>
                        <template v-slot:[`item.parcela_cartao`]="{ item }">
                            <span>
                                {{ formatParcelasCartao(item.parcela_cartao) }}
                            </span>
                        </template>
                        <template v-slot:[`item.parcela_boleto`]="{ item }">
                            <span>
                                {{ formatParcelasBoleto(item.parcela_boleto) }}
                            </span>
                        </template>
                        <template v-slot:[`item.desconto`]="{ item }">
                            <span>
                                {{ formatDinheiro(item.desconto) }}
                            </span>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-switch
                                :color="item.status == true ? 'green' : 'red'"
                                readonly
                                v-model="item.status"
                                inset
                                :style="{
                                    color: item.status == true ? 'green' : 'red'
                                }"
                            >
                            </v-switch>
                        </template>
                        <template v-slot:[`item.cartao`]="{ item }">
                            <v-switch
                                :color="
                                    item.cartao == true && item.status == true
                                        ? 'green'
                                        : 'grey'
                                "
                                readonly
                                v-model="item.cartao"
                                inset
                                :disabled="item.status"
                                :style="{
                                    color:
                                        item.cartao == true &&
                                        item.status == true
                                            ? 'green'
                                            : 'grey'
                                }"
                            >
                            </v-switch>
                        </template>
                        <template v-slot:[`item.boleto`]="{ item }">
                            <v-switch
                                :color="
                                    item.boleto == true && item.status == true
                                        ? 'green'
                                        : 'grey'
                                "
                                readonly
                                v-model="item.boleto"
                                inset
                                :disabled="item.status"
                                :style="{
                                    color:
                                        item.boleto == true &&
                                        item.status == true
                                            ? 'green'
                                            : 'grey'
                                }"
                            >
                            </v-switch>
                        </template>
                    </v-data-table>
                </v-col>
            </v-container>
        </v-card>

        <v-card>
            <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-expansion-panel-header>
                            <v-btn
                                class="ml-1"
                                ref="button"
                                :disabled="isActive"
                                block
                                medium
                                color="green white--text"
                                style="flex: inherit"
                                @click="clearDialog"
                            >
                                Criar nova Oferta
                                <v-icon class="ml-2">local_offer</v-icon>
                            </v-btn>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <Snackbar
                                v-if="snackbar"
                                :mostrarSnackbar="snackbar"
                                :corSnackbar="snackbarColor"
                                :mensagemSnackbar="mensagemAviso"
                                @fecharSnackbar="snackbar = false"
                            />
                            <div v-show="passo1">
                                <v-card class="grey lighten-4 ma-3 pa-3">
                                    <v-row>
                                        <v-col
                                            sm="3"
                                            md="5"
                                            class="ma-auto mt-3"
                                        >
                                            <v-row>
                                                <v-card
                                                    class="pa-3 ma-3"
                                                    shaped
                                                >
                                                    <v-col
                                                        class="d-flex justify-end align-center"
                                                    >
                                                        <v-icon
                                                            class="
                                                                primary--text
                                                                ma-auto
                                                                pa-n3
                                                                ma-4
                                                            "
                                                            size="100"
                                                        >
                                                            mdi-sale
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col sm="12">
                                                        <v-card-text
                                                            class="
                                                                display-1
                                                                primary--text
                                                                font-weight-black
                                                            "
                                                        >
                                                            Informe a
                                                            porcentagem de
                                                            desconto
                                                        </v-card-text>
                                                        <v-text-field
                                                            v-model="
                                                                computedDescontoPorcentagem
                                                            "
                                                            :error-messages="
                                                                checkboxErrors
                                                            "
                                                            label="Porcentagem de desconto"
                                                            not-required
                                                            prefix="%"
                                                            solo
                                                            class="
                                                                display-1
                                                                font-weight-black
                                                            "
                                                            @input="
                                                                formatDescontoPorcentagem
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card>
                                            </v-row>
                                            <v-card-text
                                                class="
                                                    title-1
                                                    grey--text
                                                    font-weight-regular
                                                "
                                            >
                                                Selecione essa opção para
                                                oferecer o desconto em formato
                                                de porcentagem (%). Ao
                                                selecionar essa opção não será
                                                possivel selecionar desconto em
                                                valor fixo (R$).
                                            </v-card-text>
                                        </v-col>
                                        <v-col
                                            sm="3"
                                            md="5"
                                            class="ma-auto mt-3"
                                        >
                                            <v-row>
                                                <v-card
                                                    class="pa-3 ma-3"
                                                    shaped
                                                >
                                                    <v-col
                                                        class="d-flex justify-end align-center"
                                                    >
                                                        <v-icon
                                                            class="
                                                                primary--text
                                                                ma-auto
                                                                pa-n3
                                                                ma-4
                                                            "
                                                            size="100"
                                                        >
                                                            mdi-cash-multiple
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col>
                                                        <v-card-text
                                                            class="
                                                                display-1
                                                                primary--text
                                                                font-weight-black
                                                            "
                                                        >
                                                            Informe o Valor de
                                                            Desconto
                                                        </v-card-text>
                                                        <v-text-field
                                                            v-model="
                                                                computedDesconto
                                                            "
                                                            :error-messages="
                                                                checkboxErrors
                                                            "
                                                            label="Valor do desconto"
                                                            not-required
                                                            clearable
                                                            solo
                                                            class="
                                                                display-1
                                                                font-weight-black
                                                            "
                                                            prefix="R$"
                                                            @input="
                                                                formatDesconto
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card>
                                                <v-card-text
                                                    class="
                                                        title-1
                                                        grey--text
                                                        font-weight-regular
                                                    "
                                                >
                                                    Selecione essa opção para
                                                    oferecer o desconto em
                                                    formato de valor Fixo (R$).
                                                    Ao selecionar essa opção não
                                                    será possivel selecionar
                                                    desconto em formato
                                                    porcentagem (%).
                                                </v-card-text>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </div>
                            <div v-show="passo2">
                                <v-row class="ma-3 pa-3">
                                    <v-col cols="12" sm="3" md="6">
                                        <v-card
                                            class="ma-3 pa-6 grey lighten-5"
                                        >
                                            <v-row>
                                                <v-icon
                                                    class="primary--text ml-5"
                                                    size="40"
                                                >
                                                    credit_card
                                                </v-icon>

                                                <v-card-title
                                                    class="primary--text"
                                                >
                                                    Desejo Oferecer pagamento
                                                    via Cartão
                                                </v-card-title>

                                                <v-switch
                                                    solo
                                                    v-model="
                                                        formulario_oferta.cartao
                                                    "
                                                    label="Ativar"
                                                    required
                                                    disabled
                                                    class="white--text ma-auto"
                                                    @change="limparCartao"
                                                ></v-switch>
                                            </v-row>
                                            <div
                                                v-show="
                                                    formulario_oferta.cartao
                                                "
                                            >
                                                <v-text-field
                                                    solo
                                                    v-model="
                                                        formulario_oferta.parcela_cartao
                                                    "
                                                    prefix="x"
                                                    label="Máximo de Parcelas"
                                                    type="number"
                                                    disabled
                                                    required
                                                ></v-text-field>
                                                <v-text-field
                                                    solo
                                                    v-model="
                                                        formulario_oferta.juros_cartao
                                                    "
                                                    label="Juros por parcela"
                                                    prefix="%"
                                                    disabled
                                                ></v-text-field>
                                            </div>
                                        </v-card>
                                        <v-card-text
                                            class="
                                                title-1
                                                grey--text
                                                font-weight-regular
                                            "
                                        >
                                            Ao ativar essa opção estará
                                            habilitando a opção de pagamento via
                                            Cartão de Crédito, podendo
                                            configurar a quantidade máxima de
                                            parcelas e juros.
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="6">
                                        <v-card
                                            class="ma-3 pa-6 grey lighten-5"
                                        >
                                            <v-row>
                                                <v-icon
                                                    class="primary--text ml-5"
                                                    size="40"
                                                >
                                                    horizontal_split
                                                </v-icon>
                                                <v-card-title
                                                    class="primary--text"
                                                >
                                                    Desejo Oferecer pagamento
                                                    via Boleto
                                                </v-card-title>
                                                <v-switch
                                                    solo
                                                    v-model="
                                                        formulario_oferta.boleto
                                                    "
                                                    label="Ativar"
                                                    required
                                                    class="white--text ma-auto"
                                                    @change="limparBoleto"
                                                ></v-switch>
                                            </v-row>
                                            <div
                                                v-show="
                                                    formulario_oferta.boleto
                                                "
                                            >
                                                <v-text-field
                                                    solo
                                                    prefix="x"
                                                    v-model="
                                                        formulario_oferta.parcela_boleto
                                                    "
                                                    :disabled="
                                                        !formulario_oferta.boleto
                                                    "
                                                    label="Máximo de Parcelas"
                                                    type="number"
                                                    required
                                                ></v-text-field>
                                                <v-text-field
                                                    solo
                                                    prefix="%"
                                                    v-model="
                                                        formulario_oferta.juros_boleto
                                                    "
                                                    :disabled="
                                                        !formulario_oferta.boleto
                                                    "
                                                    label="Juros por boleto"
                                                    required
                                                ></v-text-field>
                                            </div>
                                        </v-card>
                                        <v-card-text
                                            class="
                                                title-1
                                                grey--text
                                                font-weight-regular
                                            "
                                        >
                                            Ao ativar essa opção estará
                                            habilitando a opção de pagamento via
                                            Boleto Bancário, podendo configurar
                                            a quantidade máxima de parcelas e
                                            juros.
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-show="passo3">
                                <v-card-title
                                    class="
                                        grey--text
                                        font-weight-black
                                        justify-center
                                        title-2
                                    "
                                >
                                    <v-icon> keyboard_arrow_down </v-icon>
                                    Passo 3: Definir Prazos da Oferta:
                                </v-card-title>
                                <v-card class="ma-6 pa-3 white">
                                    <v-row>
                                        <v-col
                                            cols="8"
                                            sm="12"
                                            md="1"
                                            class="mx-auto my-auto"
                                        >
                                            <v-icon
                                                class="primary--text d-block"
                                                size="100"
                                            >
                                                date_range
                                            </v-icon>
                                        </v-col>
                                        <v-col
                                            cols="8"
                                            sm="12"
                                            md="4"
                                            class="mx-auto my-auto"
                                        >
                                            <v-card-text
                                                class="
                                                    primary--text
                                                    display-1
                                                    font-weight-black
                                                    d-block
                                                "
                                            >
                                                Início e Encerramento da Oferta
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="8" sm="12" md="4">
                                            <div>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-menu
                                                        ref="menu1"
                                                        v-model="menu1"
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :return-value.sync="
                                                            oferta.data_inicio
                                                        "
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                v-model="
                                                                    computedDateFormattedInicio
                                                                "
                                                                label="Defina o Início da Oferta"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                clearable
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="
                                                                oferta.data_inicio
                                                            "
                                                            no-title
                                                            scrollable
                                                            locale="pt-br"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    menu1 = false
                                                                "
                                                            >
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    $refs.menu1.save(
                                                                        oferta.data_inicio
                                                                    )
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </div>
                                            <div>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-menu
                                                        ref="menu2"
                                                        v-model="menu2"
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :return-value.sync="
                                                            oferta.data_fim
                                                        "
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                v-model="
                                                                    computedDateFormattedFim
                                                                "
                                                                label="Defina o Encerramento"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                persistent-hint
                                                                clearable
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="
                                                                oferta.data_fim
                                                            "
                                                            no-title
                                                            scrollable
                                                            locale="pt-br"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    menu2 = false
                                                                "
                                                            >
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    $refs.menu2.save(
                                                                        oferta.data_fim
                                                                    )
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="3"
                                            md="3"
                                            class="mx-auto my-auto"
                                        >
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-card-title
                                    class="
                                        grey--text
                                        font-weight-black
                                        justify-center
                                        title-2
                                    "
                                >
                                    <v-icon> keyboard_arrow_down </v-icon>
                                    Passo 4: Ative a Renegociação:
                                </v-card-title>
                                <v-col
                                    cols="12"
                                    sm="3"
                                    md="12"
                                    class="mx-auto my-auto"
                                >
                                    <v-card class="ma-3 pa-3 green">
                                        <v-row class="mx-auto my-auto">
                                            <v-col sm="3" md="8">
                                                <v-card-title
                                                    class="
                                                        title-2
                                                        font-weight-black
                                                        white--text
                                                    "
                                                >
                                                    Tudo pronto? Ative a
                                                    oferta<v-icon
                                                        size="40"
                                                        class="white--text"
                                                    >
                                                        double_arrow
                                                    </v-icon>
                                                </v-card-title>
                                            </v-col>
                                            <v-col
                                                sm="3"
                                                md="4"
                                                class="mx-auto my-auto"
                                            >
                                                <v-card class="ma-2 pa-2">
                                                    <v-switch
                                                        color="success"
                                                        inset
                                                        v-model="
                                                            formulario_oferta.status
                                                        "
                                                        label="Desativar/Ativar Oferta"
                                                        required
                                                        class="mx-auto my-auto"
                                                    ></v-switch>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </div>

                            <v-container>
                                <form id="ofertaForm" @submit="salvarOferta">
                                    <v-col class="text-right">
                                        <v-btn
                                            class="ml-6"
                                            dense
                                            color="green white--text"
                                            @click="salvarOferta()"
                                        >
                                            Salvar e Enviar
                                        </v-btn>
                                        <v-btn
                                            class="ml-1"
                                            color="grey darken-1"
                                            text
                                            @click="closeDialog"
                                        >
                                            Cancelar
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </form>
                            </v-container>
                        </v-expansion-panel-content>
                    </v-form>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            v-model="dialogLoading"
            width="450px"
        >
            <div class="text-center" width="420px">
                <v-card>
                    <v-progress-circular
                        class="ma-12"
                        indeterminate
                        size="150"
                        color="primary"
                    >
                        {{ mensagemLoading }}
                    </v-progress-circular>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import * as Formatter from '@/helpers/Formatter';
import OfertaService from '@/services/OfertaService';
import ApresentanteService from '@/services/apresentanteService';
import OfertaTitulo from '@/domains/OfertaTitulo';

export default {
    name: 'EnviaOferta',
    mixins: [validationMixin],

    validations: {
        cartao: { required, maxLength: maxLength(10) },
        status: { required },
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },

    props: {
        ofertas: {
            type: Array,
            required: true
        },
        detalhe_titulo: {
            type: Object,
            required: false
        }
    },

    data: () => ({
        ofertasGlobais: [],
        mensagemLoading: null,
        dialogLoading: false,
        apresentanteProtestado: null,
        wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],
        rules: [v => v.length <= 100 || 'Máximo de 100 Caracteres'],
        value: 'Esse é um SMS exemplo, onde informa os detalhes da oferta',
        mensagem: null,
        mensagemAviso: null,
        oferta: new OfertaTitulo(),
        panel: [],
        items: 1,
        dialog: false,
        menu1: null,
        menu2: null,
        modal: false,
        modal2: false,
        valid: true,
        name: '',
        passo1: true,
        passo2: true,
        passo3: true,
        cartao: null,
        isActive: false,
        data_inicio: null,
        data_fim: null,
        formulario_oferta: new OfertaTitulo(),
        tipoMensagem: null,
        snackbar: false,
        vl_saldo_protesto_formatado: null,
        vl_titulo_formatado: null,
        boleto: null,
        snackbarColor: 'green white--text',
        headers: [
            {
                text: 'Id',
                sortable: false,
                value: 'id'
            },
            {
                text: 'Status',
                sortable: false,
                value: 'status'
            },
            {
                text: 'Boleto',
                sortable: false,
                value: 'boleto'
            },
            {
                text: 'Data Início',
                sortable: false,
                value: 'data_inicio'
            },
            {
                text: 'Data de Finalização',
                sortable: false,
                value: 'data_fim'
            },
            {
                text: 'Desconto',
                sortable: false,
                value: 'desconto'
            },
            {
                text: 'Desconto Porcentagem',
                sortable: false,
                value: 'desconto_porcentagem'
            },
            {
                text: 'Juros Boleto',
                sortable: false,
                value: 'juros_boleto'
            },
            {
                text: 'Parcela Boleto',
                sortable: false,
                value: 'parcela_boleto'
            },
            { text: ' ', sortable: false, value: 'action' }
        ],
        headersGlobal: [
            {
                text: 'Id',
                sortable: false,
                value: 'id'
            },
            {
                text: 'Status',
                sortable: false,
                value: 'status'
            },
            {
                text: 'Boleto',
                sortable: false,
                value: 'boleto'
            },
            {
                text: 'Data Início',
                sortable: false,
                value: 'data_inicio'
            },
            {
                text: 'Data de Finalização',
                sortable: false,
                value: 'data_fim'
            },
            {
                text: 'Desconto',
                sortable: false,
                value: 'desconto'
            },
            {
                text: 'Desconto Porcentagem',
                sortable: false,
                value: 'desconto_porcentagem'
            },
            {
                text: 'Juros Boleto',
                sortable: false,
                value: 'juros_boleto'
            },
            {
                text: 'Parcela Boleto',
                sortable: false,
                value: 'parcela_boleto'
            },
            { text: ' ', sortable: false, value: 'action' }
        ]
    }),

    methods: {
        verificaTituloDetalhesProtestado() {
            let p_apresentante;
            let p_titulo;
            this.dialogLoading = true;
            this.mensagemLoading = 'Verificando Ofertas ...';
            p_apresentante = this.apresentanteProtestado.id;
            p_titulo = this.detalhe_titulo.id_titulo;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            ofertaService.consultaDetalhesTitulo(p_apresentante, p_titulo).then(
                response => {
                    this.ofertasGlobais = response.body.apresentante_ofertas;
                    this.dialogLoading = false;
                },
                error => {
                    this.mensagem = error;
                    console.log(error);
                }
            );
        },
        verificaApresentanteUuid() {
            this.dialogLoading = true;
            this.mensagemLoading = 'Verificando Apresentante UUID';
            let p_apresentante;
            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            apresentanteService
                .consultaApresentanteProtestado(p_apresentante)
                .then(
                    response => {
                        this.apresentanteProtestado = response.body;
                        this.dialogLoading = false;
                        this.verificaTituloDetalhesProtestado();
                    },
                    error => {
                        this.mensagem = error;
                        console.log(error);
                    }
                );
        },
        checkChar() {
            if (
                this.formulario_oferta.mensagem &&
                this.formulario_oferta.mensagem <= 100
            ) {
                return true;
            } else {
                return false;
            }
        },
        submit() {
            this.$v.$touch();
        },

        salvar() {
            this.$emit('actionSalvar');
        },

        closeDialog() {
            this.isActive = false;
            this.$emit('fechar');
            this.$refs.form.reset();
        },
        clearDialog() {
            this.$refs.form.reset();
            this.formulario_oferta.id = null;
        },
        editarOferta: function(oferta_clicada) {
            this.formulario_oferta = new OfertaTitulo();
            this.oferta = oferta_clicada;
            this.oferta.data_fim = Formatter.parseDate(this.oferta.data_fim);
            this.oferta.data_inicio = Formatter.parseDate(
                this.oferta.data_inicio
            );
            this.formulario_oferta = oferta_clicada;
            this.panel = [...Array(this.items).keys()].map((k, i) => i);
            this.isActive = true;
        },
        botaodesabilitarOferta: function(oferta) {
            this.dialogLoading = true;
            this.mensagemLoading = `Desabilitando Oferta: ${oferta.id} ...`;
            let p_oferta = oferta.id;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            ofertaService.desabilitarOfertaTitulo(p_oferta).then(
                response => {
                    if (response.status == 200) {
                        this.mensagemAviso = 'Desabilitado com sucesso';
                        this.tipoMensagem = 'success';
                        this.snackbar = true;
                        this.dialogLoading = false;
                        this.$emit('atualizar');
                    }
                },
                error => {
                    console.log(error);
                    this.mensagemAviso = 'Erro ao desabilitar Oferta';
                    this.tipoMensagem = 'error';
                    this.closeDialog();
                    this.snackbarColor = true;
                    this.snackbarColor = 'red white--text';
                }
            );
        },
        salvarOferta: function() {
            this.dialogLoading = true;
            this.mensagemLoading = 'Criando Oferta ...';
            let ofertaService = new OfertaService(Vue.http, this.$store);
            let data = this.formulario_oferta;
            data.id_titulo = this.titulo.id_titulo;
            data.data_fim = this.oferta.data_fim;
            data.data_inicio = this.oferta.data_inicio;
            data.data_fim = Formatter.parseDate(data.data_fim);
            data.data_inicio = Formatter.parseDate(data.data_inicio);
            data.desconto_porcentagem = Formatter.toFloat(
                data.desconto_porcentagem
            );
            data.desconto = Formatter.toFloat(data.desconto);
            data.desconto ? data.desconto : '0';
            data.desconto_porcentagem ? data.desconto_porcentagem : '0';
            data.juros_cartao ? data.juros_cartao : '0';
            data.parcela_cartao ? data.parcela_cartao : 1;
            data.juros_boleto ? data.juros_boleto : '0';
            data.parcela_boleto ? data.parcela_boleto : 1;
            data.protestado_entidade_uuid = this.apresentanteProtestado.id;
            ofertaService.criarOfertaTitulo(data).then(
                response => {
                    if (response.status == 200) {
                        this.mensagemAviso = 'Criado com sucesso';
                        this.tipoMensagem = 'success';
                        this.dialogLoading = false;
                        this.snackbar = true;
                        setTimeout(this.showMessage, 2000);
                    }
                },
                error => {
                    console.log(error);
                    this.mensagemAviso = 'Erro ao salvar Oferta';
                    this.tipoMensagem = 'error';
                    this.closeDialog();
                    this.snackbarColor = true;
                    this.snackbarColor = 'red white--text';
                }
            );
        },
        showMessage() {
            this.clearDialog();
            this.closeDialog();
        },
        getOfertaId(oferta) {
            return oferta.id ? oferta.id !== undefined : null;
        },
        formatDate(date) {
            if (!date) return null;
            if (date.includes('-')) {
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`;
            } else {
                return date;
            }
        },
        formatDinheiro(valor) {
            if (valor == null) {
                return 'Não definido';
            } else {
                return 'R$ ' + Formatter.formatDinheiro(valor);
            }
        },
        formatPorcentagem(valor) {
            if (valor == null) {
                return 'Não definido';
            } else {
                return Formatter.toString(valor) + '%';
            }
        },
        formatPorcentagemJurosCartao(valor) {
            if (valor == null) {
                return 'Não definido';
            } else {
                return Formatter.toString(valor) + '%';
            }
        },
        formatPorcentagemJurosBoleto(valor) {
            if (valor == null) {
                return 'Não definido';
            } else {
                return Formatter.toString(valor) + '%';
            }
        },
        formatParcelasCartao(valor) {
            if (valor == null) {
                return 'Não definido';
            } else {
                return Formatter.toString(valor) + 'x';
            }
        },
        formatParcelasBoleto(valor) {
            if (valor == null) {
                return 'Não definido';
            } else {
                return Formatter.toString(valor) + 'x';
            }
        },
        formatDateInicio() {
            return this.formatDate(this.formulario_oferta.data_inicio);
        },
        formatDateFim() {
            return this.formatDate(this.formulario_oferta.data_fim);
        },
        formatDescontoPorcentagem() {
            if (this.formulario_oferta.desconto_porcentagem) {
                let desconto = this.formulario_oferta.desconto_porcentagem;
                desconto = Formatter.toFloat(desconto);
                let valor = this.titulo.vl_saldo_protesto;
                this.formulario_oferta.desconto = Formatter.formatDinheiro(
                    (desconto / 100) * valor
                );
            }
        },
        formatDesconto() {
            if (this.formulario_oferta.desconto) {
                let desconto = this.formulario_oferta.desconto;
                desconto = parseFloat(String(desconto).replace(',', '.'));
                let valor = this.titulo.vl_saldo_protesto;
                this.formulario_oferta.desconto_porcentagem = String(
                    ((desconto / valor) * 100).toFixed(2)
                ).replace('.', ',');
            }
        },
        getBolean(valor) {
            if (valor) {
                return 'done';
            } else {
                return 'close';
            }
        },
        limparCartao() {
            if (this.formulario_oferta.cartao === false) {
                this.formulario_oferta.juros_cartao = null;
            }
            if (this.formulario_oferta.cartao === false) {
                this.formulario_oferta.parcela_cartao = null;
            }
        },
        limparBoleto() {
            if (this.formulario_oferta.boleto === false) {
                this.formulario_oferta.juros_boleto = null;
            }
            if (this.formulario_oferta.boleto === false) {
                this.formulario_oferta.parcela_boleto = null;
            }
        }
    },

    computed: {
        checkboxErrors() {
            const errors = [];
            if (!this.$v.checkbox.$dirty) return errors;
            !this.$v.checkbox.checked &&
                errors.push('You must agree to continue!');
            return errors;
        },

        selectErrors() {
            const errors = [];
            if (!this.$v.select.$dirty) return errors;
            !this.$v.select.required && errors.push('Item is required');
            return errors;
        },
        getNomeCliente() {
            return this.detalhe_titulo.nm_devedor;
        },
        getNomeSacador() {
            let name = this.detalhe_titulo.nm_sacador;
            if (name.length > 20) {
                name = name.substring(0, 20) + '...';
            }
            return name;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.maxLength &&
                errors.push('Name must be at most 10 characters long');
            !this.$v.name.required && errors.push('Name is required.');
            return errors;
        },
        options() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing
            };
        },
        element() {
            if (this.selected === 'Button') return this.$refs.button;
            else if (this.selected === 'Radio group') return this.$refs.radio;
            else return null;
        },
        computedDateFormattedInicio: {
            get: function() {
                return this.formatDate(this.oferta.data_inicio);
            },
            set: function(valor) {
                this.formulario_oferta.data_inicio = valor;
            }
        },
        computedDateFormattedFim: {
            get: function() {
                return this.formatDate(this.oferta.data_fim);
            },
            set: function(valor) {
                this.formulario_oferta.data_fim = valor;
            }
        },
        computedDescontoPorcentagem: {
            get: function() {
                return Formatter.toString(
                    this.formulario_oferta.desconto_porcentagem
                );
            },
            set: function(valor) {
                this.formulario_oferta.desconto_porcentagem = Formatter.formatPorcentagem(
                    valor
                );
            }
        },
        computedDesconto: {
            get: function() {
                return Formatter.toString(this.formulario_oferta.desconto);
            },
            set: function(valor) {
                this.formulario_oferta.desconto = Formatter.toString(valor);
            }
        },
        computedDescontoCheck() {
            return this.descontoCheck();
        },

        saldoProtestado: function() {
            return Formatter.toString(
                this.detalhe_titulo.vl_saldo_protesto_formatado
            );
        }
    },

    mounted() {
        this.titulo = this.detalhe_titulo;
        this.verificaApresentanteUuid();
    }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.titulo !== null
    ? _c(
        "div",
        [
          _vm.mostrarModalConfirmacao
            ? _c("ConfirmacaoModal", {
                attrs: {
                  showDialog: _vm.mostrarModalConfirmacao,
                  tituloModal: _vm.tituloModalConfirmacao,
                  textoModal: _vm.textoModalConfirmacao,
                  btnConfirmarEmit: "confirmarSolicitacao",
                  esconderCancelar: _vm.esconderCancelar,
                  btnConfirmar: _vm.btnConfirmarLabel
                },
                on: {
                  confirmarSolicitacao: _vm.confirmarSolicitacao,
                  fecharModal: function($event) {
                    _vm.mostrarModalConfirmacao = false
                    _vm.esconderCancelar = false
                  }
                }
              })
            : _vm._e(),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "p",
                    { staticClass: "title" },
                    [
                      _c("v-icon", { staticClass: "ma-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v("Informações do Título: ")
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "2", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Código do Status",
                              disabled: "",
                              readonly: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "6", lg: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              disabled: "",
                              readonly: "",
                              label: "Descrição do Status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4", md: "2", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              disabled: "",
                              label: "Id",
                              required: "",
                              readonly: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "2", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Nosso Número",
                              required: "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.titulo.ds_nosso_numero,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "ds_nosso_numero", $$v)
                              },
                              expression: "titulo.ds_nosso_numero"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Número do Título",
                              required: "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.titulo.ds_numero_titulo,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "ds_numero_titulo", $$v)
                              },
                              expression: "titulo.ds_numero_titulo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "1" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Parcela",
                              required: "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.titulo.nu_parcela,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nu_parcela", $$v)
                              },
                              expression: "titulo.nu_parcela"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "error-messages": _vm.$v.titulo.dt_emissao
                                .$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              dense: "",
                              "hide-details": "",
                              label: "Data de Emissão",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.dt_emissao,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "dt_emissao", $$v)
                              },
                              expression: "titulo.dt_emissao"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "error-messages": _vm.$v.titulo.dt_vencimento
                                .$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              dense: "",
                              "hide-details": "",
                              label: "Data de Vencimento",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.dt_vencimento,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "dt_vencimento", $$v)
                              },
                              expression: "titulo.dt_vencimento"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Tipo Declaração",
                              required: "",
                              "error-messages": _vm.$v.titulo.tp_declaracao
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : []
                            },
                            model: {
                              value: _vm.titulo.tp_declaracao,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "tp_declaracao", $$v)
                              },
                              expression: "titulo.tp_declaracao"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Endosso",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.tp_endosso,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "tp_endosso", $$v)
                              },
                              expression: "titulo.tp_endosso"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Valor Protestado",
                              prefix: "R$",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.vl_saldo_protesto_formatado,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.titulo,
                                  "vl_saldo_protesto_formatado",
                                  $$v
                                )
                              },
                              expression: "titulo.vl_saldo_protesto_formatado"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Valor Total do Título",
                              prefix: "R$",
                              required: "",
                              "error-messages": _vm.$v.titulo
                                .vl_titulo_formatado.$invalid
                                ? ["Este campo é obrigatorio"]
                                : []
                            },
                            model: {
                              value: _vm.titulo.vl_titulo_formatado,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "vl_titulo_formatado", $$v)
                              },
                              expression: "titulo.vl_titulo_formatado"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Aceite",
                              required: "",
                              "error-messages": _vm.$v.titulo.tp_aceite.$invalid
                                ? ["Este campo é obrigatorio"]
                                : []
                            },
                            model: {
                              value: _vm.titulo.tp_aceite,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "tp_aceite", $$v)
                              },
                              expression: "titulo.tp_aceite"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3", lg: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Espécie",
                              required: "",
                              "error-messages": _vm.$v.titulo.cd_especie_titulo
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : []
                            },
                            model: {
                              value: _vm.titulo.cd_especie_titulo,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "cd_especie_titulo", $$v)
                              },
                              expression: "titulo.cd_especie_titulo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "6", lg: "4" } },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  color: "success",
                                  loading: _vm.loadingAgrupador,
                                  items: _vm.agrupadores,
                                  label: "Agrupador",
                                  required: "",
                                  "item-text": "nm_agrupador",
                                  "item-value": "ds_agrupador",
                                  "data-cy": "tituloAgrupador",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.titulo.ds_agrupador,
                                  callback: function($$v) {
                                    _vm.$set(_vm.titulo, "ds_agrupador", $$v)
                                  },
                                  expression: "titulo.ds_agrupador"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _vm.titulo.vl_repassado != null
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "2", md: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  outlined: "",
                                  dense: "",
                                  label: "Valor Repasse",
                                  prefix: "R$",
                                  hint: "Valores repassados pelo cartório"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "p",
                    { staticClass: "title" },
                    [
                      _c("v-icon", { staticClass: "ma-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v("Informações do Devedor "),
                      _c("b", [_vm._v("(Sacado)")]),
                      _vm._v(": ")
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n8" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9", md: "9" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.$v.titulo.nm_devedor
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              outlined: "",
                              dense: "",
                              label: "Devedor",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.nm_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nm_devedor", $$v)
                              },
                              expression: "titulo.nm_devedor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9", md: "3" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##", "XX.XXX.XXX/XXXX-##"],
                                expression:
                                  "['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                              }
                            ],
                            attrs: {
                              "error-messages": _vm.$v.titulo
                                .nu_identificacao_devedor.$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              outlined: "",
                              dense: "",
                              label: "CNPJ/CPF",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.nu_identificacao_devedor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.titulo,
                                  "nu_identificacao_devedor",
                                  $$v
                                )
                              },
                              expression: "titulo.nu_identificacao_devedor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n8" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9", md: "8" } },
                        [
                          _c("v-text-field", {
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo
                                .ds_endereco_devedor.$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              label: "Endereço",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.ds_endereco_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "ds_endereco_devedor", $$v)
                              },
                              expression: "titulo.ds_endereco_devedor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.ds_bairro_devedor
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              label: "Bairro",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.ds_bairro_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "ds_bairro_devedor", $$v)
                              },
                              expression: "titulo.ds_bairro_devedor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n8" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3" } },
                        [
                          _c("v-text-field", {
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.nm_cidade_devedor
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              label: "Cidade",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.nm_cidade_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nm_cidade_devedor", $$v)
                              },
                              expression: "titulo.nm_cidade_devedor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "2" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "maska",
                                rawName: "v-maska",
                                value: "#####-###",
                                expression: "'#####-###'"
                              }
                            ],
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.cd_cep_devedor
                                .$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              label: "CEP",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.cd_cep_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "cd_cep_devedor", $$v)
                              },
                              expression: "titulo.cd_cep_devedor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "2" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "maska",
                                rawName: "v-maska",
                                value: "AA",
                                expression: "'AA'"
                              }
                            ],
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.cd_uf_devedor
                                .$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              label: "UF",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.cd_uf_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "cd_uf_devedor", $$v)
                              },
                              expression: "titulo.cd_uf_devedor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.ds_email_devedor
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              label: "E-mail do devedor",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.ds_email_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "ds_email_devedor", $$v)
                              },
                              expression: "titulo.ds_email_devedor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "2" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "maska",
                                rawName: "v-maska",
                                value: "(##) #####-####",
                                expression: "'(##) #####-####'"
                              }
                            ],
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo
                                .nu_telefone_devedor.$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              label: "Telefone do devedor",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.nu_telefone_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nu_telefone_devedor", $$v)
                              },
                              expression: "titulo.nu_telefone_devedor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "p",
                    { staticClass: "title" },
                    [
                      _c("v-icon", { staticClass: "ma-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v("Sacador ")
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n8" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9", md: "9" } },
                        [
                          _c("v-text-field", {
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Nome do Sacador",
                              required: "",
                              "error-messages": _vm.$v.titulo.nm_sacador
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : []
                            },
                            model: {
                              value: _vm.titulo.nm_sacador,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nm_sacador", $$v)
                              },
                              expression: "titulo.nm_sacador"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9", md: "3" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##", "XX.XXX.XXX/XXXX-##"],
                                expression:
                                  "['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                              }
                            ],
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.cnpj_sacador
                                .$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              label: "CNPJ",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.cnpj_sacador,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "cnpj_sacador", $$v)
                              },
                              expression: "titulo.cnpj_sacador"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n8" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9", md: "8" } },
                        [
                          _c("v-text-field", {
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo
                                .ds_endereco_sacador.$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              label: "Endereço",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.ds_endereco_sacador,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "ds_endereco_sacador", $$v)
                              },
                              expression: "titulo.ds_endereco_sacador"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "4" } },
                        [
                          _c("v-text-field", {
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.nm_cidade_sacador
                                .$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              label: "Cidade",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.nm_cidade_sacador,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nm_cidade_sacador", $$v)
                              },
                              expression: "titulo.nm_cidade_sacador"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n8" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "2" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "maska",
                                rawName: "v-maska",
                                value: "#####-###",
                                expression: "'#####-###'"
                              }
                            ],
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.cd_cep_sacador
                                .$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              label: "CEP",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.cd_cep_sacador,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "cd_cep_sacador", $$v)
                              },
                              expression: "titulo.cd_cep_sacador"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", md: "2" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "maska",
                                rawName: "v-maska",
                                value: "AA",
                                expression: "'AA'"
                              }
                            ],
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo.cd_uf_sacador
                                .$invalid
                                ? ["Valor do campo invalido"]
                                : [],
                              label: "UF",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.cd_uf_sacador,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "cd_uf_sacador", $$v)
                              },
                              expression: "titulo.cd_uf_sacador"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "p",
                    { staticClass: "title" },
                    [
                      _c("v-icon", { staticClass: "ma-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v("Cedente ")
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n8" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.$v.titulo
                                .nm_cedente_favorecido.$invalid
                                ? ["Este campo é obrigatorio"]
                                : [],
                              label: "Nome Cedente Favorecido",
                              required: ""
                            },
                            model: {
                              value: _vm.titulo.nm_cedente_favorecido,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.titulo,
                                  "nm_cedente_favorecido",
                                  $$v
                                )
                              },
                              expression: "titulo.nm_cedente_favorecido"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", sm: "2", md: "4" } }),
                      _c("v-col", { attrs: { cols: "12", sm: "2", md: "4" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-4 mt-0", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                { staticClass: "ml-5", attrs: { dense: "", color: "error" } },
                [
                  _vm._v(" Cancelar "),
                  _c("v-icon", { staticClass: "ml-2" }, [_vm._v("cancel")])
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-5",
                  attrs: {
                    disabled: _vm.$v.titulo.$invalid,
                    dense: "",
                    color: "success"
                  },
                  on: {
                    click: function($event) {
                      return _vm.solicitarProtesto()
                    }
                  }
                },
                [
                  _vm._v(" Solicitar Protesto "),
                  _c("v-icon", { staticClass: "ml-2" }, [_vm._v("done")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }